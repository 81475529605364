 function authorization(){
    var email = $('#authorization [name="email"]').val();
    var password = $('#authorization [name="password"]').val();
    $.ajax({
        type: "POST",
        dataType: "json",
        url: "/login",
        data: {email:email, password:password},
        success: function(msg){
            if(msg.login === false){
                var html = '';
                if(Object.keys(msg.errors).length > 0){
                    for(var key in msg.errors){   
                        html += msg.errors[key]+'<br>';
                    }
                }
                $('#log-error').html(html);
            }else{
                window.location.href = '/application/'
            }
        }
    });
}
$(document).ready(function () {
    $('#authorization').on('keyup', function(e){
        if (e.keyCode === 13) {
            authorization();
        }
    });    
});
